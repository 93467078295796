import "./MappingApp.css";
import { useEffect } from "react";
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";

import Query from "@arcgis/core/rest/support/Query.js";


export const MappingApp = () => {

    useEffect(() => {

        const myMap = new Map({
            basemap: "topo",
        });

        let view = new MapView({
            map: myMap,
            container: "MapApp",
            center: [-114.7420, 45.0682],
            zoom: 7,
            spatialReference: {
                wkid: 3857
            }

        });

        const labelClass = {  // autocasts as new LabelClass()
            symbol: {
                type: "text",  // autocasts as new TextSymbol()
                color: "white",
                haloColor: "Black",
                haloSize: 1,
                font: {  // autocast as new Font()
                    family: "Ubuntu Mono",
                    size: 14,
                    weight: "bold"
                }
            },
            labelPlacement: "always-horizontal",
            labelExpressionInfo: {
                expression: "$feature.AreaName"
            },
            maxScale: 0,
            minScale: 25000000,

        };

        ///http://localhost:3000/?AreaID=2775,2776
        const queryParams = new URLSearchParams(window.location.search)
        const id = queryParams.get("AreaID")
        console.log(id)

        const  hunt_areas = new FeatureLayer({
            url: "https://gisportal-idfg.idaho.gov/hosting/rest/services/Apps/Hunt_Planner/MapServer/1",
            definitionExpression: "AreaID IN (" + id + ")",
            labelingInfo: [labelClass],
            outSpatialReference: 102605
            //params: {
            //    token: temp_token
            //},
        });

        hunt_areas
            .when(() => {
                return hunt_areas.queryExtent();
            })
            .then((response) => {
                view.goTo(response.extent);
            });

        hunt_areas.popupTemplate = {
            title: "{AreaName}",
            content: [{
                type: "fields",
                fieldInfos: [{
                    fieldName: "AreaNote",
                    label: "Notes",
 //               }, {
 //                   fieldName: "AreaName",
//                    label: "Name"
                }]
            }]
        };

        myMap.add(hunt_areas)


    }, []);
    return (
        <div
            id="MapApp" style={{ height: "100vh" }}>
        </div>

    );
}
